import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import appStore from "../images/apple_badge_en.svg";
import Invite from "../layouts/invite";

const InviteContent = ({title, text}) => {
    return (
        <Invite>
            <div className="text-center">
                <div className="flex flex-col items-center justify-center gap-4 mb-14">
                    <div className="w-24 md:w-36">
                        <StaticImage src="../images/icon.png" alt="icon" quality="90" placeholder="tracedSVG"/>
                    </div>
                    <span className="text-3xl md:text-6xl font-bold text-primary">Wave Budget</span>
                </div>
                <h1 className='text-xl md:text-3xl font-bold mb-4'>{title}</h1>
                <p className="md:text-xl">{text}</p>
                <div className="flex flex-col md:flex-row items-center justify-center">
                    <Link to="https://apps.apple.com/app/apple-store/id1597718926" target="_blank" className="w-60 p-4 md:p-8">
                        <img width="100%" src={appStore} alt="Download from Apple App Store"/>
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=at.stefangaller.wave_budget" target="_blank" className="w-60">
                        <StaticImage src="../images/google-play-badge_en.png" alt="Download from Google Play" placeholder="none" />
                    </Link>
                </div>
            </div>
        </Invite>
    )
};

export default InviteContent;