import React from "react";

const Invite = ({children}) => {
    return (
        <div className="h-screen w-screen bg-primary flex justify-center items-center p-4">
            <div className="w-full md:w-auto rounded-lg bg-lightGray/60 p-8 md:p-12">
                {children}
            </div>
        </div>
    )
};

export default Invite;